import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;

export default {
 
  async getVolunteerMatches(accessToken) {
    let res = await axios.get(API_NAME + "/volunteer/stream_matches", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    return res.data;
  },
  async getVolunteerMatch(accessToken, stream_match_id) {
    let res = await axios.get(API_NAME + "/volunteer/stream_matches/" + stream_match_id, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    return res.data;
  },
  async saveMatchSpending(accessToken, form_data) {
    let res = await axios.post(API_NAME + "/volunteer/stream_match_spendings", 
        form_data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    return res.data;
  },
  async saveMatchRequest(accessToken, form_data) {
    let res = await axios.post(API_NAME + "/volunteer/stream_match_meetups", 
        form_data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    return res.data;
  },
  async signAgreement(accessToken, form_data, id) {
    let res = await axios.post(API_NAME + "/volunteer/volunteers/" + id, 
        form_data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    return res.data;
  },
  async getVolunteerDetails(accessToken, id) {
    let res = await axios.get(API_NAME + "/volunteer/volunteers/" + id, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    return res.data;
  },
  async getSimpleApiData(accessToken, endpoint) {
    let res = await axios.get(API_NAME + "/" + endpoint, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    
    return res.data;
  },

  async saveOutcome(accessToken,  form_data) {
    let res = await axios.post(API_NAME + "/volunteer/volunteer_outcomes", 
        form_data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    return res.data;
  },
  
  
  
}